/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function ScriptOverview({ script }) {
  return (

      <MDBox p={2}>
        { script.scenes.map((scene, index) => (
            <TimelineItem 
              key={index}
              color="primary"
              icon="campaign"
              title={scene.description}
              dateTime={scene.time[0]+" - "+ scene.time[1]}
              lastItem={index === script.scenes.length - 1}
            />
        ))}
        
      </MDBox>

  );
}

export default ScriptOverview;