import React, { useContext } from 'react';
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
    TextField,
    Grid,
    Card,
    Button,
    ImageList,
    ImageListItem,
} from "@mui/material";

import { AuthContext } from "context/auth";
import { FirebaseContext } from "context/firebase";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import AdPortfolio from 'components/TextAdPortfolio';

import platforms from 'constants/platforms';
import { getPlatforms } from 'constants/platforms';


function CampaignDetail() {
    const [name, setName] = useState("");
    const [theme, setTheme] = useState("");
    const [start_date, setStartDate] = useState("");
    const [end_date, setEndDate] = useState("");
    const [budget, setBudget] = useState(0);
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [campaignDoc, setCampaignDoc] = useState();
    const [saved_ads, setSavedAds] = useState([]);
    const [imageList, setImageList] = useState([]);

    const user = useContext(AuthContext)?.user;
    const firebase = useContext(FirebaseContext);
    const db = firebase.db;


    const campaignId = useParams()?.id;
    const is_new = campaignId == 'new';

    const saveCamapaign = async () => {
        setSaving(true)

        const campaignData = {
            name: name,
            theme: theme,
            start_date: start_date,
            end_date: end_date,
            budget: budget,
        }

        if (is_new) {
            firebase.createDocument("user_data/" + user.uid + "/campaigns", campaignData);
        } else {
            firebase.updateDocument("user_data/" + user.uid + "/campaigns", campaignId, campaignData, true);
        }

        setSaving(false);

    }


    const fetchData = async () => {
        setLoading(true)
        let snap, docData = await firebase.fetchDocument("user_data/" + user.uid + "/campaigns", campaignId)
        console.log(docData);
        if (docData) {
            setName(docData.name);
            if (docData.theme) setTheme(docData.theme);
            if (docData.start_date) setStartDate(docData.start_date);
            if (docData.end_date) setEndDate(docData.end_date);
            if (docData.budget) setBudget(docData.budget);
        }

        // setCampaigns(data.docs.map(doc => doc.data()));
        setCampaignDoc(docData);


        // looping through platforms
        let saved_ads = [];
        const platforms = getPlatforms();

        // Loop over platforms and query firebase
        for (let i = 0; i < platforms.length; i++) {
            const platform = platforms[i]
            console.log(platform);
            let results = await firebase.fetchCollection("user_data/" + user.uid + "/campaigns/" + campaignId + "/ads/" + platform.name + "/content/");
            console.log(results);
            if (results?.data.length > 0) {
                let ad_objetcs = {}
                ad_objetcs.platform = platform.name
                ad_objetcs.results = results.data
                saved_ads.push(ad_objetcs);
            }
        }
        setSavedAds(saved_ads);

        // Retrievinng image urls
        let imageList = [];
        const results = await firebase.fetchCollection("user_data/" + user.uid + "/campaigns/" + campaignId + "/images/");
        console.log(results);
        if (results?.data.length > 0) {
            setImageList(results.data);
        }


        setLoading(false)
    }

    useEffect(() => {
        if (!is_new) {
            console.log("fetching data");
            fetchData()
        }

    }, [])



    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <Card>
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12}>
                                <MDBox>
                                    <MDTypography variant="h6" gutterBottom>
                                        Definition
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <Grid container spacing={3} direction="row">
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            required
                                            label="Campaign Name"
                                            value={name}
                                            onChange={(event) => setName(event.target.value)}
                                            variant="standard"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            label="Theme"
                                            value={theme}
                                            onChange={(event) => setTheme(event.target.value)}
                                            variant="standard"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            type='date'
                                            label="Start Date"
                                            value={start_date}
                                            onChange={(event) => setStartDate(event.target.value)}
                                            variant="standard"

                                        />
                                        <TextField
                                            type='date'
                                            label="End Date"
                                            value={end_date}
                                            onChange={(event) => setEndDate(event.target.value)}
                                            variant="standard"

                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12}>
                                        <TextField
                                            type='number'
                                            label="Budget"
                                            value={budget}
                                            onChange={(event) => setBudget(event.target.value)}
                                            variant="standard"

                                        />
                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <MDButton variant="outlined" disabled={saving} color="primary" onClick={() => { saveCamapaign() }}>

                                            {saving ? "Saving..." : "Save"}
                                        </MDButton>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </MDBox>

                </Card>
            </MDBox>
            <MDBox py={3}>
                <Card>


                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12}>
                                <MDBox>
                                    <MDTypography variant="h6" gutterBottom>
                                        Generated Ads Porfolio
                                    </MDTypography>
                                </MDBox>
                            </Grid>

                            <Grid item xs={12}>
                                {saved_ads && saved_ads.length > 0 ? (
                                    <AdPortfolio text_proposals={saved_ads} />
                                )
                                    : (
                                        <MDBox>
                                            <MDTypography variant="body2" gutterBottom>
                                                You don't have any ads yet. Go to the  <Link to="/creative-assistant"><MDTypography color="primary"
                                                    textGradient variant="body1">Creative Assistant</MDTypography></Link> to create your first ad.
                                            </MDTypography>
                                        </MDBox>
                                    )}
                            </Grid>


                        </Grid>
                    </MDBox>



                </Card>
            </MDBox>
            <MDBox py={3}>
                <Card>


                    <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <Grid container spacing={3} direction="row">
                            <Grid item xs={12}>
                                <MDBox>
                                    <MDTypography variant="h6" gutterBottom>
                                        Generated Image Porfolio
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12}>
                                {imageList && imageList.length>0? (
                                    <Grid item xs={12} style={{ display: 'flex' }} >
                                        <ImageList cols={12} rowHeight={600}>
                                            {imageList.map((item, index) => (
                                                <ImageListItem key={index} cols={4}>
                                                    <img
                                                        src={item.image_path}
                                                        loading="lazy"
                                                    />

                                                </ImageListItem>
                                            ))}
                                        </ImageList>

                                    </Grid>
                                )
                                    : (
                                        <MDBox>
                                            <MDTypography variant="body2" gutterBottom>
                                                You don't have any generated images yet. Go to the  <Link to="/creative-assistant"><MDTypography color="primary"
                                                    textGradient variant="body1">Creative Assistant</MDTypography></Link> to create your first ad.
                                            </MDTypography>
                                        </MDBox>
                                    )}

                            </Grid>

                            <Grid item xs={12}>

                            </Grid>


                        </Grid>
                    </MDBox>



                </Card>
            </MDBox>
        </DashboardLayout>

    );
}


export default CampaignDetail;