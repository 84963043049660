import React, { useState, useContext, useEffect } from "react";
// @mui material components
//import Grid from "@mui/material/Grid";
import {
    Grid,
} from "@mui/material";

import { platforms, getPlatform, getPlatforms, getPlatformsByType } from "constants/platforms";

// Context
import { AuthContext } from "context/auth";
import { FirebaseContext } from "context/firebase";

import PropTypes from 'prop-types';
import PlatformList from "examples/Lists/PlatformList";
import ScriptCard from "examples/Cards/ScriptCard";
import SocialCard from "examples/Cards/SocialCard";
import PressCard from "examples/Cards/PressCard";
import MDButton from "components/MDButton";

function AdPortfolio(props) {
    const [selectedPlatform, setSelectedPlatform] = useState();

    let text_proposals = props.text_proposals;

    const linked_campaign = props.linked_campaign;
    console.log(text_proposals)
    console.log(linked_campaign)
    console.log(selectedPlatform)

    const user = useContext(AuthContext)?.user;
    const firebase = useContext(FirebaseContext);

    const CardAction = (linked_campaign, ad_object, setTextProposals) => {
        const save = () => {
            console.log("Saving")

            const path = "user_data/" + user.uid + "/campaigns/" + linked_campaign + "/ads/" + selectedPlatform + "/content/";
            const data = { text: ad_object.text }
            const doc = firebase.createDocument(path, data);
            ad_object.is_saved = true;
        }

        return (
            (!ad_object.is_saved &&
                <MDButton variant="gradient" color="primary" size="small" onClick={save} > Save </MDButton>
            )
        )
    }

    return (

        <Grid container spacing={3} direction="row">
            <Grid item xs={2} >
                <PlatformList
                    title="Select a platform to see generated suggestions"
                    platforms={text_proposals}
                    selected={selectedPlatform}
                    setSelected={setSelectedPlatform}
                />

            </Grid>
            <Grid item xs={10}>
                {text_proposals.filter((text_proposal) => text_proposal.platform === selectedPlatform).map((text_proposal, index) => (
                    <Grid container spacing={3} key={index}>

                        {text_proposal.results.map((proposal, index) => (


                            <Grid item key={index} xs={12} md={getPlatform(text_proposal.platform).type == 'social' ? 4 : 12} lg={getPlatform(text_proposal.platform).type == 'social' ? 4 : 12}>
                                {(() => {
                                    switch (getPlatform(text_proposal.platform).type) {
                                        case 'social':
                                            return <SocialCard title={"Variant " + (index + 1)} description={proposal.text} action={CardAction(linked_campaign, proposal)} />
                                        case 'script':
                                            return <ScriptCard title="Script Overview" script={proposal.json} linked_campaign={linked_campaign} />
                                        case 'press':
                                            return <PressCard title={"Proposal " + (index + 1)} description={proposal.text} action={CardAction(linked_campaign, proposal)} />
                                        default:
                                            return <SocialCard title={"Variant " + (index + 1)} description={proposal.text} action={CardAction(linked_campaign, proposal)} />
                                    }
                                })()}

                            </Grid>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>

    );

}

export default AdPortfolio