/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import CreativeAssistant from "layouts/creative_assistant";
import Campaigns from "layouts/campaigns";
import Knowledge from "layouts/knowledge";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import CampaignDetail from "layouts/campaign_detail";

import FirebaseSignInScreen from "layouts/authentication/firebasesigni-in";
import FirebaseSignOut from "layouts/authentication/sign-out";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Campaigns",
    key: "campaigns",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/campaigns",
    component: <Campaigns />,
  },
  {
    type: "detail",
    name: "Campaign Detail",
    key: "campaigndetail",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/campaigns/:id",
    component: <CampaignDetail />,
  },
  {
    type: "collapse",
    name: "Creative Assistant",
    key: "creative-assistant",
    icon: <Icon fontSize="small">lightbulb</Icon>,
    route: "/creative-assistant",
    component: <CreativeAssistant />,
  },
  {
    type: "collapse",
    name: "Knowledge AI",
    key: "knowledge-ai",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/knowledge-ai",
    component: <Knowledge />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
    public: true,
    private: false,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
    public: true,
    private: false,
  },
  {
    type: "collapse",
    name: "Sign Out",
    key: "sign-out",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/sign-out",
    component: <FirebaseSignOut />,
    public: false,
  },
];

export default routes;
