/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useContext } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

import {
  createUserWithEmailAndPassword,
} from "firebase/auth";

import { FirebaseContext } from "context/firebase";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import defaultParam from "constants/defaults";
import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";

// Images
import imagen_blue from "assets/images/imagen_blue.png";
import BasicLayout from "../components/BasicLayout";





function SignUp() {

  // Email and password
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const [rememberMe, setRememberMe] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);


  const firebase = useContext(FirebaseContext);
  const auth = firebase.auth;
  const db = firebase.db;

  const registerWithEmailAndPassword = async () => {
    try {
      const res = await createUserWithEmailAndPassword(auth, email, password);
      const user = res.user;
      const docRef = doc(db, "user_data", user.uid);
      await setDoc(docRef, {
        brand: defaultParam.brand,
        text_prompt: defaultParam.text_prompt,
        script_prompt: defaultParam.script_prompt,
      }, { merge: false });
      firebase.logAnalytics("sign_up", { method: "email" });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };


  return (
    <CoverLayout image={imagen_blue}>
      <MDBox py={3}>
        <Grid container spacing={3} direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12} md={12} lg={12}></Grid>

          <Card>
            <MDBox
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="success"
              mx={2}
              mt={-3}
              p={3}
              mb={1}
              textAlign="center"
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                Register!
              </MDTypography>
              <MDTypography display="block" variant="button" color="white" my={1}>
                Enter your email and password to register
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox mb={2}>
                  <MDInput type="text" label="Name" variant="standard" fullWidth value={name} onChange={(event) => setName(event.target.value)} />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="email" label="Email" variant="standard" fullWidth value={email} onChange={(event) => setEmail(event.target.value)} />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput type="password" label="Password" variant="standard" fullWidth value={password} onChange={(event) => setPassword(event.target.value)} />
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" fullWidth onClick={registerWithEmailAndPassword}>
                    sign in
                  </MDButton>
                </MDBox>
                <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography variant="button" color="text">
                    Already have an account?{" "}
                    <MDTypography
                      component={Link}
                      to="/sign-in"
                      variant="button"
                      color="info"
                      fontWeight="medium"
                      textGradient
                    >
                      Sign In
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </MDBox>

      </CoverLayout>
  );
}

export default SignUp;
