/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

import Avatar from "@mui/material/Avatar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import { Button } from "@mui/material";

import { Facebook } from "@mui/icons-material";
import { getPlatform } from "constants/platforms";

import colors from "assets/theme/base/colors";

function PlatformList({ title, platforms, selected, setSelected }) {
  
  //if selected is null, select the first platform in the list
  if (!selected) {
    setSelected(platforms[0].platform);
    console.log("Setting seleced to: " + platforms[0].platform);
  }

  const renderProfiles = platforms.map(({ platform,index }) => (
    <MDBox key={index} component="li" display="flex" alignItems="center" py={1} mb={1}>
      
      <Button onClick={() => { setSelected(platform) }}>
        <MDBox mr={2}>
        

          <Avatar sx={{...( selected==platform && {backgroundColor: "#f62e72"})}}>
            {getPlatform(platform).icon}
          </Avatar>

        </MDBox>
        <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" sx={{ display: { xs: 'none', md: 'block'  } }}>
          <MDTypography variant="button" fontWeight="medium">
            {getPlatform(platform).label}
          </MDTypography>

      
        </MDBox>
        </Button>

    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={2} px={2} alignItems="center" sx={{ display: { xs: 'none', md:'none',lg: 'block'  } }} >
        <MDTypography variant="h6" fontWeight="regular"  verticalAlign="middle">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}


// Typechecking props for the ProfilesList
PlatformList.propTypes = {
  title: PropTypes.string.isRequired,
  platforms: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PlatformList;
