/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Grid} from "@mui/material";
import { OutlinedInput, FormControl } from "@mui/material";


import { AuthContext } from "context/auth";
import { FirebaseContext } from "context/firebase";
import { collection, doc, setDoc, getDoc, getDocs } from "firebase/firestore";
import MDButton from "components/MDButton";

import { Info } from "@mui/icons-material";

// Defaults
import defaultParam from "constants/defaults";
import PromptTable from "../PromptTable";

function PlatformSettings(props) {

  const [brand, setBrand] = useState("");
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [text_prompt, setTextPrompt] = useState("");
  const [script_prompt, setScriptPrompt] = useState("");
  const [press_prompt, setPressPrompt] = useState("");
  const [press_prompt_examples, setPressPromptExamples] = useState([])
  const [social_prompt_examples, setSocialPromptExamples] = useState([])
  const [script_prompt_examples, setScriptPromptExamples] = useState([])

  
  // get isSideBar from props. If not provided, default to false
  const isSidebar = props.isSidebar || false;

  // Initialize Firebase
  const firebase_app = useContext(FirebaseContext);
  const db = firebase_app.db;



  // Get user data
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      fetchData()
    }
    // if (press_prompt_examples.length === 0) {
    //   setPressPromptExamples([{ prompt: "", output: "" }])
    // }

  }, [])

  if (!user) {
    return null;
  }

  const fetchData = async () => {
    setLoading(true)

    const docRef = doc(db, "user_data", user.uid);
    const snap = await getDoc(docRef);

    let param = snap.data();

    if (!param) {
      param = defaultParam;
    }

    if (!param.brand) {
      param.brand = defaultParam.brand;
    }
    if (!param.text_prompt) {
      param.text_prompt = defaultParam.text_prompt;
    }
    if (!param.script_prompt) {
      param.script_prompt = defaultParam.script_prompt;
    }
    console.log("param")
    console.log(param)

    if (!param.press_prompt) {
      param.press_prompt = defaultParam.press_prompt;
    }

    setBrand(param.brand);
    setTextPrompt(param.text_prompt);
    setScriptPrompt(param.script_prompt);
    setPressPrompt(param.press_prompt);
    setPressPromptExamples(param.press_prompt_examples)
    setLoading(false)
  }

  const setData = async () => {
    setSaving(true)
    console.log("saving")
    console.log(press_prompt_examples)

    const docRef = doc(db, "user_data", user.uid);
    await setDoc(docRef, {
      brand: brand,
      text_prompt: text_prompt,
      script_prompt: script_prompt,
      press_prompt: press_prompt,
      press_prompt_examples: press_prompt_examples
    }, { merge: true });


    setSaving(false)
  }




  return (

    <Card sx={{ boxShadow: "none" }}>

      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
        platform settings
      </MDTypography>

      <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase" mt={1}>
        Global Settings
      </MDTypography>

      <Grid container spacing={3}>
        <Grid item xs={12} mt={3}>


          <FormControl fullWidth variant="outlined">

            <MDInput label="Brand" placeholder="Brand" value={brand} onChange={(e) => setBrand(e.target.value)} />
          </FormControl>
        </Grid>
        <Grid item xs={12} >
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase" mt={1}>
            AD Media Model Settings
          </MDTypography>
        </Grid>
        <Grid item xs={12} >
          <OutlinedInput
            id="ad_prompt_input"
            fullWidth
            multiline
            rows={8}
            onChange={(event) => setTextPrompt(event.target.value)}
            value={text_prompt}
            label="Image Prompt"
            placeholder="Text generation Prompt"
          />

        </Grid>
        {!isSidebar && (
          <Grid item lg={12} md={12} xs={12} >
            <MDTypography variant="caption" fontWeight="bold" color="text" mt={1}>
              <Info />  Optionally, provide some examples of social posts you like to tailor the model for your needs.
            </MDTypography>
            <PromptTable data={social_prompt_examples} dataUpdate={setSocialPromptExamples} />
          </Grid>
        )

        }

        <Grid item xs={12}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase" mt={1}>
            A/V Script Model Settings
          </MDTypography>
        </Grid>
        <Grid item xs={12} >

          <OutlinedInput
            id="script_prompt_input"
            fullWidth
            multiline
            rows={8}
            onChange={(event) => setScriptPrompt(event.target.value)}
            value={script_prompt}
            label="Script Prompt"
            placeholder="Text generation Prompt"
          />

        </Grid>
        <Grid item xs={12}>
          <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase" mt={1}>
            Press Model Settings
          </MDTypography>
        </Grid>
        <Grid item lg={12} md={12} xs={12} >


          <OutlinedInput
            id="press_prompt_input"
            fullWidth
            multiline
            rows={8}
            onChange={(event) => setPressPrompt(event.target.value)}
            value={press_prompt}
            label="Press Prompt"
            placeholder="Press release generation Prompt"
          />

        </Grid>
        {!isSidebar && (
          <Grid item lg={12} md={12} xs={12} >
            <MDTypography variant="caption" fontWeight="bold" color="text" mt={1}>
              <Info />  Optionally, provide some examples of press releases you like to tailor the model for your needs.
            </MDTypography>
            <PromptTable data={press_prompt_examples} dataUpdate={setPressPromptExamples} />
          </Grid>
        )
        }

        <Grid item xs={12} >
          <MDButton variant="contained" onClick={setData}>{saving ? "Saving" : "Save"}</MDButton>
        </Grid>
      </Grid>

    </Card>

  );
}

export default PlatformSettings;
