import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { OutlinedInput, IconButton, Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import { Delete } from '@mui/icons-material';
import MDTypography from 'components/MDTypography';



export default function PromptTable(props) {
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        // check if prps.data is empty, and init with default value
        console.log("props.data")
        console.log(props.data)
        if (!props.data || props.data.length === 0) {
            const new_data = [{ prompt: "", output: "" }]
            setData(new_data)
            props.dataUpdate(new_data)
        }
        else {
        setData([...props.data])
        }
    }, [props.data])




    console.log(data)

    const addRowTable = () => {
        const new_item = {
            prompt: "",
            output: "",
        };
        setData([...data, new_item]);
    };

    const tableRowRemove = (index) => {
        const dataRow = [...data];
        dataRow.splice(index, 1);
        setData(dataRow);
        props.dataUpdate(dataRow)
    };

    const onChangeInput = (e, index) => {
        console.log(e.target)
        console.log(e.target.name)
        const { name, value } = e.target;
        const new_data = [...data];
        new_data[index][name] = value;
        setData(new_data);
        props.dataUpdate(new_data)
    }

    return (
        <MDBox mb={2} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ display: "table-header-group" }}>
                    <TableRow>
                        <TableCell align="center" key="id" style={{ minWidth: 50 }}></TableCell>
                        <TableCell align="left" key="prompt" style={{ minWidth: 170 }}><MDTypography fontWeight="bold" variant="caption">Prompt</MDTypography></TableCell>
                        <TableCell align="left" key="output" style={{ minWidth: 170 }}><MDTypography fontWeight="bold" variant="caption">Output</MDTypography></TableCell>
                        <TableCell ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                    {data && data.length > 0 && data.map((row, index) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {index + 1}
                            </TableCell>
                            <TableCell align="right">
                                <OutlinedInput
                                    fullWidth
                                    value={row.prompt}
                                    multiline
                                    rows={2}
                                    label="Press Prompt"
                                    placeholder="Press release generation Prompt"
                                    name="prompt"
                                    onChange={(e) => onChangeInput(e, index)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <OutlinedInput
                                    fullWidth
                                    value={row.output}
                                    multiline
                                    rows={2}
                                    label="Valid output"
                                    placeholder="Enter a valid/example output"
                                    name="output"
                                    onChange={(e) => onChangeInput(e, index)}
                                />
                            </TableCell>
                            <TableCell align="right">
                                <IconButton aria-label="delete" onClick={() => tableRowRemove(index)} >
                                    <Delete />
                                </IconButton>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <MDButton variant="contained" onClick={addRowTable}>Add</MDButton>
        </MDBox>
    );
}