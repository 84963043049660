import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
    TextField,
    FormControl,
    Grid,
    Button,
} from "@mui/material";
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import BoltIcon from '@mui/icons-material/Bolt';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import config from "constants";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useMediaQuery from '@mui/material/useMediaQuery';

import MDButton from "components/MDButton";
import MDBox from "components/MDBox"

import { FirebaseContext } from "context/firebase";
import { AuthContext } from "context/auth";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};


const ImageModal = (props) => {
    const [image, setImage] = useState(null);
    const [image_prompt, setPrompt] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [working_image, setWorkingImage] = useState(null);
    const [variant_image_list, setVariantImageList] = useState(null);
    const [saving, setSaving] = useState(false);

    const matches = useMediaQuery('(min-width:600px)');

    const firebase = useContext(FirebaseContext);
    const user = useContext(AuthContext)?.user;

    const imageBase64 = props?.imageBase64;
    const isOpen = props?.isOpen;
    const setVisibility = props?.setVisibility;
    const campaignId = props?.campaignId;

    console.log("ImageModal campaignId: " + campaignId);


    // Define server URL based on environment
    const serverURL = config.url.API_URL;

    const saveImageToCampaign = async (event) => {
        try {
            setSaving(true);
            setError(null);
            const path = user.uid + "/" + campaignId;
            const response = await axios.post(serverURL + "/save_image", {
                path: path,
                imageb64: working_image
            });
            setSaving(false);
            const data = response.data;
            if (data.error) {
                setError(data.error);
                console.log(data.error);
                return;
            } else {
                // if there was no error, save the image path to the campaign in firestore
                let result = firebase.createDocument("user_data/" + user.uid + "/campaigns/" + campaignId + "/images/", { image_path: data.url });
            }
            console.log(data);
        } catch (error) {
            setSaving(false);
            console.error(error);
        }
    };


    const fetchImage = async (image_prompt, image) => {
        try {
            setError(null);
            const response = await axios.post(serverURL + "/modify_image_with_prompt", {
                prompt_request: image_prompt,
                imageb64: image
            });
            setLoading(false);
            const data = response.data;
            if (data.error) {
                setError(data.error);
                console.log(data.error);
                return;
            }
            console.log(data);
            setVariantImageList(data);
        } catch (error) {
            console.error(error);
        }
    };


    useEffect(() => {
        if (imageBase64) {
            setImage(imageBase64);
            setWorkingImage(imageBase64);
        }
    }, [imageBase64]);

    const handleCloseModal = () => {
        setVisibility(false);
    };

    const handleChangeText = (event) => {
        setPrompt(event.target.value);
    };

    const regenerateImage = (event) => {
        setLoading(true);
        fetchImage(image_prompt, image);
    };


    return (
        <>
            {isOpen && (

                <BootstrapDialog
                    fullWidth
                    maxWidth='lg'
                    open={isOpen}
                    onClose={handleCloseModal}
                >
                    <BootstrapDialogTitle onClose={handleCloseModal}>Image tuning</BootstrapDialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={12} >
                                <DialogContentText>
                                    Interact with the Image Generator to fine tune your image.
                                </DialogContentText>
                            </Grid>

                            {
                                variant_image_list && variant_image_list.length > 0 && (
                                    <>

                                        <Grid item xs={12} >

                                            <ImageList cols={matches ? 4 : 2} >
                                                <ImageListItem key="Subheader" cols={1} style={{ height: 'auto' }}>
                                                    <img sx={{ maxWidth: '100', maxHeight: '100' }}
                                                        src={`data:image/jpeg;base64,${working_image}`}
                                                        loading="lazy"
                                                    />
                                                    <ImageListItemBar
                                                        sx={{
                                                            background:
                                                                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                        }}
                                                        title="Original Image"
                                                        position="top"
                                                        actionIcon={
                                                            <IconButton
                                                                sx={{ color: 'white' }}
                                                                aria-label={`star `}
                                                            >
                                                                <StarBorderIcon />
                                                            </IconButton>
                                                        }
                                                        actionPosition="left"
                                                    />

                                                </ImageListItem>
                                                {variant_image_list.map((item, index) => (
                                                    <ImageListItem key={index} cols={1}>
                                                        <img sx={{ maxWidth: '100', maxHeight: '100' }}
                                                            src={`data:image/jpeg;base64,${item}`}
                                                            loading="lazy"
                                                        />
                                                        <ImageListItemBar
                                                            sx={{
                                                                background:
                                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                                            }}
                                                            title={`Variant ${index + 1}`}
                                                            position="top"
                                                            actionIcon={
                                                                <IconButton
                                                                    sx={{ color: 'white' }}
                                                                    aria-label={`Variant ${index + 1}`}
                                                                    onClick={() => {
                                                                        setWorkingImage(item);
                                                                        setVariantImageList(null);
                                                                    }}
                                                                >
                                                                    <StarBorderIcon />
                                                                </IconButton>
                                                            }
                                                            actionPosition="left"
                                                        />

                                                    </ImageListItem>
                                                ))}

                                            </ImageList>
                                        </Grid>
                                    </>
                                ) || (
                                    <Grid item xs={12} >
                                        <Grid container justifyContent="center"
                                            alignItems="center">
                                            <img
                                                src={`data:image/jpeg;base64,${working_image}`}
                                                loading="lazy"

                                            />
                                        </Grid>
                                    </Grid>
                                )
                            }

                            {
                                error && (
                                    <Grid item xs={12} >
                                        <Alert severity="error">{error}</Alert>
                                    </Grid>
                                )

                            }
                            <Grid item md={12} xs={12} >
                                <MDBox>
                                    <FormControl fullWidth>

                                        <TextField
                                            id="outlined-textarea"
                                            placeholder="Type your intstructions here"
                                            multiline
                                            fullWidth
                                            value={image_prompt}
                                            variant="outlined"
                                            onChange={handleChangeText}>
                                        </TextField>

                                    </FormControl>
                                </MDBox>

                            </Grid>
                            <Grid item md={4} xs={6} >
                                <MDButton color="primary"
                                    variant="outlined"
                                    disabled={!image_prompt || loading}
                                    onClick={regenerateImage} >
                                    <BoltIcon />{loading ? "Applying" : "Apply changes"}</MDButton>
                            </Grid>
                            {campaignId && (
                                <Grid item md={4} xs={6} >
                                    <MDButton color="primary"
                                        variant="outlined"
                                        disabled={saving}
                                        onClick={saveImageToCampaign} >
                                        <BoltIcon />{saving ? "Saving" : "Save"}</MDButton>
                                </Grid>

                            )}

                        </Grid>

                    </DialogContent>


                </BootstrapDialog>

            )}
        </>
    );
};

export default ImageModal;
