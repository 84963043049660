
// Setting a default paramater object
const defaultParam = {
    brand: "Cymbal",
    text_prompt: "Generate an ad with following inputs:\n\
    Audience age category: {1}\n\
    Social Media Platform: {2}\n\
    Add the end of the ad, add 2 hashtags, not more\n\
    The brand: {0}\n\
    Do not include any promo code\n\
    Important: Include emojis in the ad to make it more appealing\n\
    Output language must be {3}\n\
    Optimize the output for the give social media platform\n\
    Output only the ad text\n\
    Theme: {4}",
    script_prompt: "Generate a script that will be used in a {2} ad, using following parameters:\n\
    Audience age category: {1}\n\
    The brand: {0}\n\
    For each sequence, add the starting and ending time, in seconds\n\
    Output the result as a valid JSON object with a strucure containing scenes with description and time keys.\n\
    Do not add any leading or trailing characters outside the json object\n\
    Max length:900 characters\n\
    Theme: {4}",
    press_prompt: "Generate a press release, using following parameters:\n\
    Audience age category: {1}\n\
    The brand: {0}\n\
    Max length:1000 characters\n\
    Theme: {4}",
}

export default defaultParam;