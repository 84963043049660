// Import FirebaseAuth and firebase.
import React, { useContext } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { getAuth } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FirebaseContext } from 'context/firebase';



// Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
};



function FirebaseSignInScreen() {
    const firebase = useContext(FirebaseContext);
    const firebase_app = firebase.firebase_app;

    return (
        <div>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(firebase_app)} />
        </div>
    );
}

export default FirebaseSignInScreen