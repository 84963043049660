import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { Description, Instagram, LinkedIn, Radio, Tv, YouTube } from "@mui/icons-material";

// declare the list of platoforms with a type attribute and their icon
const platforms = [
    { name: "facebook",label:"Facebook" , icon: <FacebookRoundedIcon />, type: "social" },
    { name: "twitter",label:"Twitter", icon: <TwitterIcon />, type: "social" },
    { name: "linkedin",label:"LinkedIn", icon: <LinkedIn />, type: "social" },
    { name: "pressrelease",label:"Press Release", icon: <Description />, type: "press" },
    { name: "tv", label:"TV",icon: <Tv />, type: "script" },
    { name: "radio",label:"Radio", icon: <Radio />, type: "script" },
    { name: "youtube",label:"Youtube", icon: <YouTube />, type: "script" },

];

export const getPlatforms = () => {
    return platforms;
}

export const getPlatform = (name) => {
    return platforms.find((platform) => platform.name === name);
}

export const getPlatformsByType = (type) => {
    return platforms.filter((platform) => platform.type === type);
}

export default platforms;
