/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useContext, useEffect } from "react";
import axios from "axios";


// @mui material components
//import Grid from "@mui/material/Grid";
import {
  Box,
  MenuItem,
  Select,
  Checkbox,
  TextField,
  InputAdornment,
  Typography,
  Grid,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Card,
  IconButton,
  ImageList,
  ImageListItem,
  OutlinedInput,
  Switch,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Custom Components
import config from "constants"
import ImageModal from "components/SCAImageModal";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SocialCard from "examples/Cards/SocialCard";

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BoltIcon from '@mui/icons-material/Bolt';

// Context
import { AuthContext } from "context/auth";
import { FirebaseContext } from "context/firebase";
import { platforms, getPlatform, getPlatforms, getPlatformsByType } from "constants/platforms";


import AdPortfolio from "components/TextAdPortfolio";



// JS format string
String.prototype.format = function () {
  // store arguments in an array
  var args = arguments;
  // use replace to iterate over the string
  // select the match and check if the related argument is present
  // if yes, replace the match with the argument
  return this.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is present
    return typeof args[index] == 'undefined' ? match : args[index];
  });
};


function CreativeAssistant() {

  // Define server URL based on environment
  const serverURL = config.url.API_URL;

  const firebase = useContext(FirebaseContext);
  const db = firebase.db;

  const { user } = useContext(AuthContext);



  const [prompt, setPrompt] = useState();
  const [scriptPrompt, setScriptPrompt] = useState();
  const [pressPrompt, setPressPrompt] = useState();
  const [brand, setBrand] = useState();
  const [image, setImage] = useState(null);
  const [imageList, setImageList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [text_proposals, setText] = useState([]);
  const [description, setDescription] = useState("");
  const [loading_ad, setLoadingAd] = useState(false);
  const [loading_image, setLoadingImage] = useState(false);
  const [age, setAge] = useState("30-40");
  const [imageType, setImageType] = useState("");
  const [imageStyle, setImageStyle] = useState("");
  const [language, setLanguage] = useState("english");
  const [value, setValue] = React.useState(0);
  const [imageGenerationPrompt, setImageGenerationPrompt] = useState("");
  const [image_to_edit, setImageToEdit] = useState(null);
  const [selectedPlatform, setSelectedPlatform] = useState();
  const [text_generation_error, setTextGenerationError] = useState("");
  const [link_to_campaign, setLinkToCampaign] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [campaign_data, setCampaignData] = useState([]);
  const [facts, setFacts] = useState("");
  const [press_prompt_examples, setPressPromptExamples] = useState([]);

  const [openImageModal, setOpenImageModel] = useState(false);

  const handleOpenImageModal = (clicked_image) => {
    setImageToEdit(clicked_image);
    setOpenImageModel(true);

  };

  useEffect(() => {
    fetchData()
    initCampaigns()
  }, [])

  useEffect(() => {
    console.log("Text proposals changed");
    console.log(text_proposals);
  }, [text_proposals])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const initCampaigns = async () => {
    let { docs, data } = await firebase.fetchCollection("user_data/" + user.uid + "/campaigns");
    setCampaignData(data);
    setCampaigns(docs);

  }

  const fetchData = async () => {
    setLoadingAd(true)

    let snap, param = await firebase.fetchDocument("user_data", user.uid);

    setBrand(param.brand);
    setPrompt(param.text_prompt);
    setScriptPrompt(param.script_prompt);
    setPressPrompt(param.press_prompt);
    setPressPromptExamples(param.press_prompt_examples);
    setLoadingAd(false)
  }




  // Make an asynchronous request to the Vertex Gen AI to generate an image
  const fetchImage = async (imagePrompt) => {
    try {
      const response = await axios.post(serverURL + "/generate_image", {
        prompt_request: imagePrompt,
      });
      const data = response.data;
      setImageList(data);
      setLoadingImage(false);
      firebase.logAnalytics("image_generated", { imagePrompt: imagePrompt });
    } catch (error) {
      console.error(error);
    }
  };


  // Make an asynchronous request to the Vertex Gen AI Studio API to get a list of available models.
  const fetchText = async (description, platform) => {
    try {
      // merging the prompt with the description
      // Depending on the platform type, we need to use a different prompt
      let merged_prompt = "";
      let platform_type = getPlatform(platform).type;
      let variations = 3;

      // Setting Model defaults
      let tempature = 0.5;
      let token_limit = 256;
      let topK = 40;
      let topP = 0.8;

      if (platform_type === "social") {
        merged_prompt = prompt.format(brand, age, platform, language, description, facts);
      } else if (platform_type === "script") {
        merged_prompt = scriptPrompt.format(brand, age, platform, language, description, facts);
        variations = 1;
        tempature = 0.1;
        token_limit = 1024;
      } else if (platform_type === "press") {
        // Loop over the press prompt examples and extract them as a concatenated string with a new line between each example
        let examples = "";
        if (press_prompt_examples != null) {
          for (let i = 0; i < press_prompt_examples.length; i++) {
            examples += "Theme:" + press_prompt_examples[i].prompt + "\n";
            examples += "Output:" + press_prompt_examples[i].output + "\n";
          }
        }
        merged_prompt = pressPrompt.format(brand, age, platform, language, description, facts, examples);
        variations = 2;
        tempature = 0.2;
        token_limit = 1024;
      }

      description = merged_prompt.format(brand, age, platform, language, description);
      console.log(description);

      // Error handling if the POST request fails
      let response;
      try {
        response = await axios.post(serverURL + "/generate_text", {
          prompt_request: description,
          platform: platform,
          variations: variations,
          tempature: tempature,
          token_limit: token_limit,
          top_k: topK,
          top_p: topP,
        });
        
      } catch (error) {
        setLoadingAd(false);
        setTextGenerationError("Text generation failed. Please try again.");
      }

      let data = response.data;
      console.log(data);
      setLoadingAd(false);

      // Resturcture the data object: move all the text lines under data.results.text
      data.results = [];
      for (let i = 0; i < data.text.length; i++) {
        data.results.push({ "text": data.text[i] });
      }
      delete data.text;

      // Go throught the data, and if the platform is script, we need to parse the json and get the script as an array
      if (platform_type === "script") {
        let script = [];


        for (let i = 0; i < data.results.length; i++) {
          console.log('script content');
          let tempData = data.results[i].text;
          // Clean the tempData to remove everything before the first { and after the last }
          tempData = tempData.substring(tempData.indexOf('{'), tempData.lastIndexOf('}') + 1);
          console.log(tempData);
          const strippedData = JSON.parse(tempData);
          data.results[i].json = strippedData;

          script.push(strippedData);
        }
      }

      console.log('FINAL DATA');
      console.log(data);

      // Add the data to text_proposals. As useState is asynchronous, we need to use the spread operator to get the previous value of text_proposals.
      setText((prev) => [...prev, data]);
    } catch (error) {
      console.error(error);
    }
  };

  // Make an asynchronous request to the Vertex Gen AI Studio API to get a list of available models.
  const fetchImagePrompt = async (description) => {
    try {
      // merging the prompt with the description
      let prompt_feature = "Generate the best possible prompt for an Generative Image AI to illustrate the following description: {0} \n\
      Be as specific and descriptive as possible.\n\
      Do not include any text in the image, like signs, logos, etc. ";
      // Include Image style and type if they are not null
      if (imageStyle != null) {
        prompt_feature += " Image style: " + imageStyle;
      }
      if (imageType != null) {
        prompt_feature += " Image type: " + imageType;
      }

      description = prompt_feature.format(description);
      console.log(description);

      const response = await axios.post(serverURL + "/generate_image_prompt", {
        prompt_request: description,
      });
      firebase.logAnalytics("image_prompt_generated", { prompt_request: description });
      const data = response.data;
      console.log(data);
      // Add the data to text_proposals. As useState is asynchronous, we need to use the spread operator to get the previous value of text_proposals.
      setImageGenerationPrompt(data);
    } catch (error) {
      console.error(error);
    }
  };


  const generateImage = () => {
    setImage(null);
    setImageList([]);
    setLoadingImage(true);
    setOpenImageModel(false);

    fetchImage(imageGenerationPrompt);
  };


  // Generate text for each platform.
  const generateText = () => {
    setText([]);
    setSelectedPlatform(null);
    setLoadingAd(true);

    let platform = [];
    let platforms = getPlatforms();
    //for all the platforms in the platform list, check if they are checked and add them to the platform list
    for (var i = 0; i < platforms.length; i++) {
      if (document.getElementById(platforms[i].name).checked) {
        platform.push(platforms[i].name);
      }
    }


    console.log(platform);
    firebase.logAnalytics("text_generated", { platform: platform });

    // For each platform, make an asynchronous request to the Vertex Gen AI Studio API to generate text.
    platform.forEach((platform) => {
      fetchText(description, platform);
    });
  };

  const handleClickOpenSettings = () => {
    setOpen(true);
  };

  const handleCloseSettings = () => {
    setOpen(false);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    Shape your campaign
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  label="Enter your description here"
                  onChange={(event) => setDescription(event.target.value)}
                  variant="outlined"
                  fullWidth
                />


              </Grid>
              <Grid item md={8} xs={12}>
                <TextField
                  label="Additionnal Context/Facts"
                  onChange={(event) => setFacts(event.target.value)}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                />


              </Grid>
              <Grid item md={8} xs={12}>
                <Grid container spacing={3} justifyContent="center">
                  <Grid item xs={6}>
                    <FormGroup row>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={age}
                          label="Age"
                          onChange={(event) => setAge(event.target.value)}
                        >
                          <MenuItem value={"15-20"}>15-20</MenuItem>
                          <MenuItem value={"20-30"}>20-30</MenuItem>
                          <MenuItem value={"30-40"}>30-40</MenuItem>
                          <MenuItem value={"40-50"}>40-50</MenuItem>
                          <MenuItem value={"50+"}>50+</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <FormGroup row>
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-label">Language</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={language}
                          label="Age"
                          onChange={(event) => setLanguage(event.target.value)}
                        >
                          <MenuItem value={"french"}>French</MenuItem>
                          <MenuItem value={"english"}>English</MenuItem>
                          <MenuItem value={"brazilan"}>Brazilan</MenuItem>
                          <MenuItem value={"spanish"}>Spanish</MenuItem>
                        </Select>
                      </FormControl>
                    </FormGroup>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Social Media
                    </MDTypography>
                    <FormGroup row>
                      {getPlatformsByType("social").map((platform) => (

                        <FormControlLabel key={platform.name} control={<Checkbox id={platform.name} color="primary" />} label={platform.label} />
                      )
                      )}

                    </FormGroup>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      A/V Media
                    </MDTypography>
                    <FormGroup row>
                      {getPlatformsByType("script").map((platform) => (

                        <FormControlLabel key={platform.name} control={<Checkbox id={platform.name} color="primary" />} label={platform.label} />
                      )
                      )}

                    </FormGroup>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Print
                    </MDTypography>
                    <FormGroup row>
                      {getPlatformsByType("press").map((platform) => (

                        <FormControlLabel key={platform.name} control={<Checkbox id={platform.name} color="primary" />} label={platform.label} />
                      )
                      )}

                    </FormGroup>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                      <MDBox mt={0.5}>
                        <Switch checked={link_to_campaign} onChange={() => setLinkToCampaign(!link_to_campaign)} />
                      </MDBox>
                      <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                          Link to Campaign
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    {link_to_campaign && (
                      <MDBox mt={1.5}>
                        <FormControl variant="standard" fullWidth>
                          <InputLabel id="campaign-label">Campaign</InputLabel>
                          <Select
                            labelId="campaign-label"
                            id="campaign_selector"
                            value={campaign}
                            label="Campaign"
                            onChange={(event) => setCampaign(event.target.value)}
                          >
                            {(campaigns && campaigns.length > 0) && campaigns.map((campaign, index) => (
                              <MenuItem key={campaign.id} value={campaign.id}>{campaign_data[index].name}</MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      </MDBox>
                    )
                    }
                  </Grid>

                  <Grid item xs={12}>
                    <MDButton color="primary"
                      variant="outlined"
                      disabled={!description || loading_ad}
                      onClick={generateText} >
                      <BoltIcon />{loading_ad ? "Generating" : "Generate"}</MDButton>

                  </Grid>
                </Grid>


              </Grid>

            </Grid>
            <MDBox>

            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
      {text_proposals && text_proposals.length > 0 && (
        <MDBox py={3}>
          <Card>
            <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <Grid container spacing={3} direction="row">
                <Grid item xs={12}>
                  <MDBox>
                    <MDTypography variant="h6" gutterBottom>
                      AI Assistant Suggestions
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={12}>
                  <AdPortfolio text_proposals={text_proposals} linked_campaign={campaign} setText={setText} />
                </Grid>


              </Grid>
            </MDBox>
          </Card>
        </MDBox>

      )}

      <MDBox py={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography variant="h6" gutterBottom>
                    AI Image Generation
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-prompt">Image Generation Prompt</InputLabel>
                  <OutlinedInput
                    id="standard-multiline-static"
                    multiline
                    rows={8}
                    onChange={(event) => setImageGenerationPrompt(event.target.value)}
                    value={imageGenerationPrompt}
                    endAdornment={
                      <InputAdornment position="end" sx={{ p: 3 }}>
                        <IconButton
                          aria-label="Generate Prompt"
                          onClick={(event) => fetchImagePrompt(description)}
                          edge="end"
                        >
                          <AutoFixHighIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Image Prompt"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="image-type-label">Image Type</InputLabel>
                  <Select
                    labelId="image-type-label"
                    id="image-type"
                    value={imageType}
                    onChange={(event) => setImageType(event.target.value)}
                    label="Image Type"
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    <MenuItem value={"Portrait"}>Portrait</MenuItem>
                    <MenuItem value={"Digital painting"}>Digital painting</MenuItem>
                    <MenuItem value={"Concept art"}>Concept art</MenuItem>
                    <MenuItem value={"Ultra realistic illustration"}>Ultra realistic illustration</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="image-type-label">Image Style</InputLabel>
                  <Select
                    labelId="image-type-label"
                    id="image-type"
                    value={imageStyle}
                    onChange={(event) => setImageStyle(event.target.value)}
                    label="Image Type"
                  >
                    <MenuItem value="">
                      <em></em>
                    </MenuItem>
                    <MenuItem value={"highly detailed"}>highly detailed</MenuItem>
                    <MenuItem value={"hyperrealistic"}>hyperrealistic</MenuItem>
                    <MenuItem value={"pop-art"}>pop-art</MenuItem>
                    <MenuItem value={"Modernist"}>Modernist</MenuItem>
                    <MenuItem value={"Ultra realistic illustration"}>Ultra realistic illustration</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <MDButton color="primary"
                  variant="outlined"
                  disabled={!imageGenerationPrompt || loading_image}
                  onClick={generateImage} >
                  <BoltIcon />{loading_image ? "Generating" : "Generate"}</MDButton>
              </Grid>
              <Grid item xs={12}>
                {imageList && (
                  <Grid item xs={12} style={{ display: 'flex' }} >
                    <ImageList cols={12} rowHeight={600}>
                      {imageList.map((item, index) => (
                        <ImageListItem key={index} cols={4}>
                          <img
                            src={`data:image/jpeg;base64,${item}`}
                            loading="lazy"
                            onClick={(event) => handleOpenImageModal(item)}
                          />

                        </ImageListItem>
                      ))}
                    </ImageList>

                  </Grid>
                )}
              </Grid>

            </Grid>
          </MDBox>
        </Card>
      </MDBox>

      <ImageModal
        isOpen={openImageModal}
        setVisibility={setOpenImageModel}
        imageBase64={image_to_edit}
        campaignId={campaign}
      />



      <Footer />
    </DashboardLayout>
  );
}

export default CreativeAssistant;
