import React, { useEffect, useState, useContext } from "react";
import { FirebaseContext } from "context/firebase";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";


export const AuthContext = React.createContext();



//3.
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const firebase = useContext(FirebaseContext);
  const firebase_app = firebase.firebase_app;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(firebase_app), (user) => {
      setUser(user)
      setLoading(false)
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, loading }}>{children}</AuthContext.Provider>
  );
};

// Signout function
export const Signout = () => {
  const firebase = useContext(FirebaseContext);
  const firebase_app = firebase.firebase_app;
  return getAuth(firebase_app).signOut();
};

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null, loading: auth.loading }
}