// Import FirebaseAuth and firebase.
import React, { useContext } from 'react';
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import 'firebase/compat/auth';
import { Signout } from 'context/auth';

function FirebaseSignOut() {
    
    Signout();

    return (
        <Navigate to="/" />
    );

}

export default FirebaseSignOut