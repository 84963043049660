/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import { Link } from "react-router-dom";
import platforms from "constants/platforms";
import { getPlatform } from "constants/platforms";

export default function data(campaignDocs) {

  const Campaign = ({ name, doc }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>

      <Link
        to={"/campaigns/" + doc.id}
        state={{
          campaignDoc: doc,
        }}
      >
        <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
          {doc.data().name}
        </MDTypography>
      </Link>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Campaign Name", accessor: "campaigns", width: "45%", align: "left" },
      { Header: "media", accessor: "media", width: "10%", align: "left" },
      { Header: "budget", accessor: "budget", align: "center" },
    ],

    rows: [
      // Expand all the rows from rawData
      ...campaignDocs.map((row) => ({
        // The first column is the campaign name
        campaigns: <Campaign doc={row} />,
        media: (  // The second column is the media
          <MDBox display="flex" py={1}>
            {row.media?.map((media) => {
              return getPlatform(media).icon
            })}
          </MDBox>
        ),
        budget: (  // The third column is the budget
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {row.data().budget}
          </MDTypography>
        ),
      })),
    ],
  };
}
