/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ScriptOverview from "components/ScriptDisplay";
import { CardActions } from "@mui/material";

function SocialCard({ title, description,action }) {
  return (
    <Card sx={{ height: '100%' }}>
      <MDBox p={3} sx={{ height: '100%' }}>
        <MDTypography display="inline" variant="h6" textTransform="capitalize" fontWeight="bold">
          {title}
        </MDTypography>
        <MDBox mt={2} mb={3}>
          <MDTypography variant="body1" component="p" color="text">
            {description}
          </MDTypography>
        </MDBox>
        
      </MDBox>
      <CardActions>
        {action}
      </CardActions>
    </Card>
  );
}

// Typechecking props for the SimpleBlogCard
SocialCard.propTypes = {
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
      "default",
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default SocialCard;
