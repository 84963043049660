import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import { AuthContext } from "context/auth";

import { FirebaseContext } from "context/firebase";
import { getPlatforms } from "constants/platforms";


// Data
import data from "layouts/dashboard/components/Projects/data";
import MDButton from "components/MDButton";



function Projects() {
  const [loading, setLoading] = useState(false)
  const [campaignDocs, setCampaignDocs] = useState([])
  const [table_data, setData] = useState();



  // Initialize Firebase
  const firebase_app = useContext(FirebaseContext);
  const db = firebase_app.db;

  // Get user data
  const { user } = useContext(AuthContext);

  const fetchData = async () => {
    setLoading(true)
    let results = await firebase_app.fetchCollection("user_data/" + user.uid + "/campaigns")
    const platforms = getPlatforms();

    // We need to aggregate the actuals platforms present on the campaign
    // Loop over platforms and query firebase
    for (let j = 0; j < results.docs.length; j++) {
      const campaign_id = results.docs[j].id;
      results.docs[j].media = []
      for (let i = 0; i < platforms.length; i++) {
        const platform = platforms[i]
        let ad_list = await firebase_app.fetchCollection("user_data/" + user.uid + "/campaigns/" + campaign_id + "/ads/" + platform.name + "/content/");
        if (ad_list?.data.length > 0) {
          results.docs[j].media.push(platform.name)
          //saved_ads.push(ad_objetcs);
        }
      }

    }

    setCampaignDocs(results.docs);

    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const rawData = data(campaignDocs)
    console.log(rawData);
    setData(rawData);
  }, [campaignDocs])





  //const { columns, rows } = data2();
  const [menu, setMenu] = useState(null);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Projects
          </MDTypography>
        </MDBox>
        <MDBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </MDBox>
        {renderMenu}
      </MDBox>
      <MDBox>
        <Link
          to={"/campaigns/new"}
        >
          <MDButton variant="contained" color="primary" sx={{ ml: 3, mb: 3 }}>
            Create New Project
          </MDButton>
        </Link>
      </MDBox>

      <MDBox mb={5}>

        {loading ? (
          <MDBox sx={{ justifyContent: 'center',display:'flex' }}><CircularProgress/></MDBox>
        ) : (

          table_data && (
            <DataTable
              table={table_data}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
              entriesPerPage={false}
            />
          )

        )}

      </MDBox>
    </Card>
  );
}

export default Projects;
