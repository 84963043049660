// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAnalytics,logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { createContext, useContext, useReducer, useMemo } from "react";
import { collection, doc, setDoc, getDoc, getDocs, addDoc } from "firebase/firestore";

// Material Dashboard 2 React main context
export const FirebaseContext = createContext();

// Setting custom name for the context which is visible on react dev tools
FirebaseContext.displayName = "FirebaseContext";

// Material Dashboard 2 React custom hook for using context
function useFirebaseController() {
    const context = useContext(FirebaseContext);

    if (!context) {
        throw new Error(
            "useFirebaseController should be used inside the FirebaseControllerProvider."
        );
    }

    return context;
}

// Material Dashboard 2 React context provider
function FirebaseControllerProvider({ children }) {
    const firebaseConfig = {
        apiKey: "AIzaSyCWZ1w-4l023chiWRZ__gukwoiqr66mOe0",
        authDomain: "sca-marketing-studio.firebaseapp.com",
        projectId: "sca-marketing-studio",
        storageBucket: "sca-marketing-studio.appspot.com",
        messagingSenderId: "167936397098",
        appId: "1:167936397098:web:8355dffe486ab3726f5853",
        measurementId: "G-DTZHPZVW49"
    };

    // Initialize Firebase
    const firebase_app = initializeApp(firebaseConfig);
    const db = getFirestore(firebase_app);
    const auth = getAuth(firebase_app);
    const analytics = getAnalytics(firebase_app);

    const logAnalytics = (event_name, event_params) => {
        console.log("LOGGING EVENT");
        console.log(event_name,event_params);
        logEvent(analytics, event_name,event_params);
    }


    const fetchDocument = async (path, id) => {
        const docRef = doc(db, path, id);
        const snap = await getDoc(docRef);
        let snapData = snap.data();
        console.log(snapData);
        return snap, snapData;
    }




    const fetchCollection = async (path) => {
        const docRef = collection(db,path);
        const results = await getDocs(docRef);
        const documents = results.docs;
        const documents_data = documents.map(doc => doc.data())
        return {'docs':documents,'data':documents_data}
    }

    const updateDocument = async (path, id, data, merge) => {
        const docRef = doc(db, path, id);
        const snap = await setDoc(docRef, data, { merge: true });
        return snap;
    }

    const createDocument = async (path, data) => {


        const docRef = collection(db, path);
        const snap = await addDoc(docRef, data);

        return snap;
    }

    return <FirebaseContext.Provider value={{ firebase_app, logAnalytics,db, auth, fetchDocument, updateDocument, createDocument,fetchCollection }}>{children}</FirebaseContext.Provider>;
}



export default FirebaseControllerProvider;