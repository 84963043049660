/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {
  Grid,
  Card
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";



function Knowledge() {


  return (
    <DashboardLayout>
      <MDBox py={3}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <Grid container spacing={3} direction="row">
              <Grid item xs={12}>
                <MDBox>
                  <MDTypography variant="h3" gutterBottom>
                    Coming Soon!
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="body2" gutterBottom>
                    A Vertex AI powered Search Engine for all your knowledge needs.
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>

    </DashboardLayout>
  );
}

export default Knowledge;
