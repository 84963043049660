
const prod = {
    url: {
      API_URL: "https://studio.sca-demos.com/api"
    }
  };
  
  const dev = {
    url: {
      API_URL: "http://localhost:5000/api"
    }
  };

  const config = process.env.NODE_ENV === "development" ? dev : prod;

  export default config